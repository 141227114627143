<template>
  <div class="layout-main bg-white">
    <div class="journey detail custom-z-index" v-if="journeyData">
      <div class="header journey-block" v-if="isOwner || isMember">
        <div class="fs-h2 text-md-center" v-text="journeyData.name"></div>
        <div class="option-btn-group d-flex justify-content-md-center">
          <button class="btn common-oval-btn dark fs-capation-12" @click="downloadGPXClicked">GPX</button>
          <button class="btn common-oval-btn dark fs-capation-12" @click="downloadDiagramClicked">軌跡圖</button>
          <button class="btn common-oval-btn dark fs-capation-12" @click="planBookClicked">計劃書</button>
        </div>
        <b-dropdown class="more-option" variant="link" right no-caret>
          <template #button-content>
            <Icon name="more_horiz" size="24" />
          </template>
          <b-dropdown-item-button v-if="isOwner && isExpired" button-class="d-flex justify-content-between align-items-center" @click="groupDuplicate">
            <span>建立新團</span>
            <Icon name="paste" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="isOwner && !isExpired" button-class="d-flex justify-content-between align-items-center" @click="journeyEdit">
            <span>編輯行程</span>
            <Icon name="edit" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="isOwner && !isExpired" button-class="d-flex justify-content-between align-items-center" @click="toMemberEdit">
            <span>團員管理</span>
            <Icon name="user-group-solid" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button button-class="d-flex justify-content-between align-items-center" @click="copyLinkClicked">
            <span>分享行程</span>
            <Icon name="share" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="isOwner" button-class="d-flex justify-content-between align-items-center" @click="deleteBtnClicked">
            <span>刪除行程</span>
            <Icon name="volume" size="24" />
          </b-dropdown-item-button>
          <b-dropdown-item-button v-if="isMember && !isExpired" button-class="d-flex justify-content-between align-items-center" @click="quitBtnClicked">
            <span>退出行程</span>
            <Icon name="quit" size="24" />
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="header journey-block d-flex justify-content-md-center" v-if="!isExpired && !isOwner && !isMember">
        <button class="btn common-oval-btn dark fs-capation-12 apply-btn" @click="toMemberJoin">加入此登山活動</button>
      </div>
      <div class="journey-content journey-block">
        <div class="block-title fs-h4 pt-3" v-if="isOwner || isMember">計畫內容</div>
        <div class="operating-list">
          <div class="operating-item" v-if="!isOwner && joinedMembers">
            <div class="name">
              <div class="fs-body-16">主辦人</div>
            </div>
            <div class="info">
              <div class="master-info d-flex align-items-center">
                <div class="avatar circle-32px">
                  <img :src="joinedMembers.owner?.pic_url" alt="avatar">
                </div>
                <span class="fs-title-14" v-text="joinedMembers.owner?.name"></span>
              </div>
            </div>
          </div>
          <div class="operating-item" v-if="!isOwner && !isMember">
            <div class="name">
              <div class="fs-body-16">行程路線</div>
            </div>
            <div class="info">
              <div class="fs-h4" v-text="groupData.name"></div>
            </div>
          </div>
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">出發日期</div>
            </div>
            <div class="info">
              <div class="fs-title-18">{{ moment(journeyData.journey[0].startTimestamp).format('YYYY/MM/DD (dd) HH:mm') }}</div>
            </div>
          </div>
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">下山時間</div>
            </div>
            <div class="info">
              <div class="fs-title-18">{{ moment(journeyData.journey[journeyData.journey.length - 1].endTimestamp).format('YYYY/MM/DD (dd) HH:mm') }}</div>
            </div>
          </div>
          <div class="operating-item">
            <div class="name">
              <div class="fs-body-16">行程天數</div>
            </div>
            <div class="info">
              <div class="fs-title-18" v-text="durationDays"></div>
            </div>
          </div>
          <router-link :to="{name: 'GroupDetailSubmit'}" class="operating-item" v-if="isOwner || isMember">
            <div class="name">
              <div class="fs-body-16">申請資訊</div>
            </div>
            <div class="info">
              <span class="fs-title-18" v-text="startRegMsg"></span>
              <span class="fs-body-18" v-if="passRule.areaHouseRule"> [預先登記後抽籤]</span>
            </div>
          </router-link>
          <div class="operating-item cursor-pointer" v-if="isOwner || isMember" @click="openPageModal">
            <div class="name">
              <div class="fs-body-16">參與人員</div>
            </div>
            <div class="info">
              <div class="fs-title-18" v-text="participantCount"></div>
            </div>
          </div>
<!--          <div class="operating-item">-->
<!--            <div class="name">-->
<!--              <div class="fs-body-16">接駁服務</div>-->
<!--            </div>-->
<!--            <div class="info">-->
<!--              <div class="fs-title-18">尚未預訂接駁</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="journey-plan journey-block">
        <div class="block-title fs-h4">路線規劃</div>
        <div class="content-list">
          <div class="content-item d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="directions_walk" size="20" />
                <div class="info-text fs-body-16">行走</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="formatTimeToHoursAndMinutes(journeyData.pathInfo.totalWalkTime)"></span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="distance" size="20" />
                <div class="info-text fs-body-16">距離</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ Math.round(journeyData.pathInfo.totalDistance / 1000 * 10) / 10 }}</span>
                <span class="unit">km</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-up" size="20" />
                <div class="info-text fs-body-16">總升</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ journeyData.pathInfo.totalUp }}</span>
                <span class="unit">m</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-down" size="20" />
                <div class="info-text fs-body-16">總降</div>
              </div>
              <div class="info-value fs-h2">
                <span>{{ journeyData.pathInfo.totalDown }}</span>
                <span class="unit">m</span>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="name">
              <div class="fs-body-16">行走速率</div>
            </div>
            <div class="info">
              <div class="fs-title-18">x {{ journeyData.climbRate }} (標準)</div>
            </div>
          </div>
          <div class="content-item">
            <div class="journey-info row">
              <div class="col-12 col-md-6">
                <div class="box trail-map" :class="{ 'scale-active': mapScale }">
                  <img class="thumbnail d-md-none" :src="[journeyData.picUrl]" alt="路線縮圖">
                  <button class="btn scale d-md-none" @click="mapScaleToggle">
                    <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                  </button>
                  <div class="path-map-group custom-z-index common-transition">
                    <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
                    <button class="btn scale d-md-none" @click="mapScaleToggle">
                      <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box overflow-auto">
                  <journey-viewer :journey="journeyData" :show-opt-btn="false" :show-weather="false" :show-start-time-select="false"></journey-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-if="journeyData" modal-class="download-diagram" ref="diagram-modal" size="lg" centered hide-header hide-footer v-model="showDiagramModal">
      <path-download-diagram :path-serial="journeyData.baseOnPathSerial" :path-info="journeyData" :window-width="windowWidth" @close-modal="downloadDiagramClicked()">
        <journey-drawer class="d-none" v-if="drawerDataReady" style="width: 100%" ref="rcanvas" :size="1000" :showInfo="true" :journey="journeyData" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="journeyData.name"></journey-drawer>
      </path-download-diagram>
    </b-modal>

    <transition name="fade">
      <pageModal v-if="pageModalOpen" title="參與人數" :desc="joinedMembersNumber" back-btn-type="back" :optionBtn="false" @close="closePageModal" >
        <GroupDetailMember ref="member" :groupData="groupData" :journeyData="journeyData" :joinedMembers="joinedMembers" @back="closePageModal" />
      </pageModal>
    </transition>

  </div>
</template>

<script>
import moment from "moment/moment";
import JourneyViewer from "@/components/common/JourneyViewer.vue";
import PathMap from "@/components/common/PathMap.vue";
import PathDownloadDiagram from "@/views/path/PathDownloadDiagram.vue";
import JourneyDrawer from "@/components/common/JourneyDrawer.vue";
import {mapState} from "vuex";
import pageModal from "@/components/common/pageModal.vue";
import GroupDetailMember from "@/views/group/GroupDetailMember.vue";

export default {
  name: 'GroupDetail',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      groupData: null,
      journeyData: null,
      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],
      joinedMembers: {},
      passRule: {},
      startRegMsg: '',
      tempId: -1,

      windowWidth: window.innerWidth,
      showDiagramModal: false,
      drawConfig: {
        hideBlankMilestones: false,
        config: null,
      },
      drawerDataReady: false,

      mapScale: false,
      pageModalOpen: false,
    };
  },
  props: {
  },
  components: {
    pageModal, GroupDetailMember,
    JourneyDrawer, PathDownloadDiagram,
    PathMap,
    JourneyViewer,
  },
  computed: {
    ...mapState(['user']),

    isOwner() {
      return this.user && this.user.serial === this.groupData?.groupMasterSerial;
    },
    isMember() {
      if (!this.user) {
        return false;
      }
      return this.joinedMembers.groupList ? this.joinedMembers.groupList.some(member => member.userSerial === this.user.serial) : false;
    },
    isExpired() {
      const theDayAfterEndDate = moment(this.journeyData.endDate);
      theDayAfterEndDate.add(1, 'day');
      return moment().isAfter(theDayAfterEndDate);
    },
    moment() {
      return moment
    },
    durationDays() {
      const dayMappings = {
        1: '單攻',
      };

      for (let i = 2; i <= 9; i++) {
        dayMappings[i] = `${i}天`;
      }

      if (this.journeyData && this.journeyData.durationDays in dayMappings) {
        return dayMappings[this.journeyData.durationDays];
      } else {
        return '資料獲取失敗';
      }
    },
    participantCount() {
      return this.joinedMembers.groupList ? this.joinedMembers.groupList.length + 1 : 1;
    },
    joinedMembersNumber() {
      return this.joinedMembers.groupList && this.groupData.joinLimit ? '（ ' + (this.joinedMembers.groupList.length + 1) + ' / '+ this.groupData.joinLimit + ' ）' : '';
    },
	},
  watch: {
    
  },
  async mounted() {
    const journeyId = this.$route.params.id;
    await this.readInfo(journeyId);
	},
  methods: {
    async readInfo(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.groupData = await this.$store.dispatch('api/getGroupDetailPromise', journeyId);
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', this.groupData.journeys[0]);
        this.buildFullPathAndHeights(this.journeyData.journey);
        this.buildPathMilestones(this.journeyData.journey);
        // const dayTemplatesRequest = await this.$store.dispatch('api/getPathDayTemplatePromise', this.journeyData.baseOnPathSerial);
        // const targetTemp = dayTemplatesRequest.find(item => item.days === this.journeyData.durationDays);
        // this.tempId = targetTemp.serial;
        // const testIsJoinedRes = await this.$store.dispatch('api/postGroupTestIsJoinedPromise', this.groupData.serial);
        // if(testIsJoinedRes.isJoined) {
        //   this.joinedMembers = {};
        //   this.joinedMembers = await this.$store.dispatch('api/getGroupMembersPromise', this.groupData.serial);
        //   if (this.joinedMembers.groupList) {
        //     const userItem = this.joinedMembers.groupList.find(item => item.serial === this.user.serial);
        //     if (userItem) {
        //       this.joinedMembers.groupList = this.joinedMembers.groupList.filter(item => item.serial !== this.user.serial);
        //       this.joinedMembers.groupList.unshift(userItem);
        //     }
        //   }
        // }

        this.joinedMembers = {};
        this.joinedMembers = await this.$store.dispatch('api/getGroupMembersPromise', this.groupData.serial);
        if (this.joinedMembers.groupList && this.user) {
          const userItem = this.joinedMembers.groupList.find(item => item.serial === this.user.serial);
          if (userItem) {
            this.joinedMembers.groupList = this.joinedMembers.groupList.filter(item => item.serial !== this.user.serial);
            this.joinedMembers.groupList.unshift(userItem);
          }
        }

        this.passRule = await this.$store.dispatch('api/getJourneyPassRulePromise', this.groupData.journeys[0]);
        this.calcStartRegMsg();
        this.drawerDataReady = true;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    formatTimeToHoursAndMinutes(value) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    buildFullPathAndHeights(journeyItems) {
      // const _this = this;
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      journeyItems.forEach((item) => {
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng,
          });
        } else if (item.type === 'route') {
          const detail = item.detail;
          const routepts = detail.routepts;
          const heights = detail.heights;

          if (item.direction === 0) {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights);
          } else {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.reverse().map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights.reverse());
          }
        }
      });
    },
    buildPathMilestones(journeyItems) {
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    calcStartRegMsg() {
      let d = moment(this.journeyData.startDate);
      if (this.passRule.areaHouseRule !== null) {
        this.startRegMsg = d.format('YYYY/MM/DD') + ' 開始申請' + this.passRule.areaHouseRule.houseName
      } else {
        this.startRegMsg = d.format('YYYY/MM/DD') + ' 開始申請'
      }
    },
    // toChineseNumber(number) {
    //   const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    //   const units = ['', '十', '百', '千', '萬', '億', '兆'];
    //   const numberStr = number.toString();
    //   let result = '';
    //   for (let i = 0; i < numberStr.length; i++) {
    //     const digit = parseInt(numberStr[i]);
    //     if (digit !== 0) {
    //       result += chineseNumbers[digit] + units[numberStr.length - i - 1];
    //     } else {
    //       if (i === numberStr.length - 1 || parseInt(numberStr[i + 1]) !== 0) {
    //         result += chineseNumbers[digit];
    //       }
    //     }
    //   }
    //   return result;
    // },
    mapScaleToggle() {
      this.mapScale = !this.mapScale;
    },
    downloadGPXClicked() {
      // const baseUrl = process.env.VUE_APP_BASE_URL;
      const journeyId = this.journeyData._id;
      const journeyGPXLink = this.$store.state.api.URLS.getJourneyGPX;
      
      const downloadUrl = `${window.location.protocol}//${window.location.host}${journeyGPXLink}/${journeyId}`;
      // console.log(downloadUrl);
      const fileName = `${this.groupData.name}_Joyhike.gpx`;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', downloadUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function() {
        if (this.status === 200 || this.status === 304) {
          var myBlob = this.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(myBlob);
          link.download = fileName;
          link.click();
        }
      };
      xhr.send();
      // window.open(downloadUrl, '_blank');
    },
    downloadDiagramClicked() {
      if (this.user === null) {
        this.$router.push({name: 'Login'});
      } else {
        this.showDiagramModal = !this.showDiagramModal;
      }
    },
    planBookClicked() {
      this.$router.push({
        name: 'GroupPlanBook',
      });
    },
    async journeyEdit() {
      await this.$router.push({ name: 'GroupDetailEdit' });
    },
    async deleteBtnClicked() {
      let r = confirm('確定刪除【' + this.groupData.name + '】?');
      if (r) {
        try {
          await this.$store.dispatch('api/postGroupDeletePromise', this.groupData._id);
          alert('成功刪除【' + this.groupData.name + '】');
          await this.$router.push({ name: 'MyPlan' });
        } catch (error) {
          await this.$store.dispatch('appendErrorMsg', error.toString());
        }
      }
    },
    async quitBtnClicked() {
      if(this.isMember) {
        let r = confirm('確定離開【' + this.groupData.name + '】?');
        if (r) {
          try {
            await this.$store.dispatch('api/postGroupLeavePromise', this.groupData.serial);
            alert('成功離開【' + this.groupData.name + '】');
            await this.$router.push({ name: 'MyPlan' });
          } catch (error) {
            await this.$store.dispatch('appendErrorMsg', error.toString());
          }
        }
      }
    },
    async groupDuplicate() {
      let r = confirm('確定以【' + this.groupData.name + '】為藍本建立新團?');
      if (r) {
        try {
          const res = await this.$store.dispatch('api/postGroupDuplicatePromise', this.groupData._id);
          alert('成功以【' + this.groupData.name + '】為藍本建立新團，即將前往編輯新團');
          if(res && res.group._id) {
            await this.$router.push({ name: 'GroupDetailEdit', params: { id: res.group._id } });
          }
        } catch (error) {
          await this.$store.dispatch('appendErrorMsg', error.toString());
        }
      }
    },
    copyLinkClicked() {
      // window.copyMsg(window.location.href);
      // alert('已複製分享連結!');
      const url = window.location.href;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
          alert('已複製分享連結!');
        }).catch(err => {
          console.error('Failed to copy URL: ', err);
          alert('複製分享連結失敗。');
        });
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = url;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          alert('已複製分享連結!');
        } catch (err) {
          console.error('Failed to copy URL: ', err);
          alert('複製分享連結失敗。');
        }
        document.body.removeChild(textArea);
      }
    },
    toMemberEdit() {
      this.$router.push({ name: 'GroupDetailMember' });
    },
    toMemberJoin() {
      if (this.user === null) {
        this.$store.commit('setRedirectRoute', window.location.pathname);
        this.$router.push({name: 'Login'});
      } else {
        this.$router.push({ name: 'GroupDetailMemberJoin' });
      }
    },
    openPageModal() {
      this.pageModalOpen = true;
    },
    closePageModal() {
      this.pageModalOpen = false;
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.journey.detail {
  .master-info {
    gap: 1rem;
    .avatar {
      border: 2px solid $color-dark;
    }
    .fs-title-14 {
      color: $color-bk-tp-50;
    }
  }
  .apply-btn {
    width: 33%;
    @include smaller-than-medium {
      width: 100%;
    }
  }
  .journey-content.journey-block {
    padding: 0;
    @include smaller-than-medium {
      padding: .5rem 1rem;
    }
  }
}
</style>
<style lang="scss">
  @import "src/assets/scss/journey";
</style>
