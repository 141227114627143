<template>
  <div class="page-modal detail-sub-page custom-z-index">
    <div class="page-container">
      <div class="page-nav">
        <div class="back-btn" @click.prevent="backClicked">
          <Icon v-if="backBtnType === 'close'" name="close" size="24" />
          <Icon v-if="backBtnType === 'back'" name="arrow_back_ios" size="24" />
        </div>
        <h2 class="fs-h2">{{title}}</h2>
        <h5 class="fs-h5 d-none d-md-block" v-if="desc">{{desc}}</h5>
        <div class="btn option-btn common-oval-btn dark fs-body-18" v-if="optionBtn" v-text="optionBtnText" @click.prevent="saveClicked"></div>
      </div>
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'page-modal',
  data() {
    return {
    }
  },
  computed: {
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    desc: {
      type: String,
      required: false,
      default: '',
    },
    backBtnType: {
      type: String,
      default: 'close',
    },
    optionBtn: {
      type: Boolean,
      default: false,
    },
    optionBtnText: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    backClicked() {
      this.$emit('close');
    },
    saveClicked() {
      this.$emit('save')
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@import "src/assets/scss/detailSubPage";
.page-modal {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--unit-100vh) - 70px);
  background-color: $color-white;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  @include smaller-than-medium {
    height: var(--unit-100vh);
  }
  @include bigger-than-medium {
    padding: 0 2rem;
  }
  .page-container {
    width: 918px;
    padding-bottom: .75rem;
    .page-nav {
      position: sticky;
      top: 0;
      z-index: 2;
      background: $color-white;
      width: 100%;
      min-height: 44px;
      flex: 0 0 auto;
      padding: 1.5rem 2.5rem 1.5rem;
      border-bottom: solid 1px $color-border;
      text-align: center;
      @include smaller-than-medium {
        .back-btn {
          left: .5rem;
        }
      }
      .back-btn, .option-btn {
        cursor: pointer;
      }
      .option-btn {
        position: absolute;
        top: 1rem;
        right: 0;
        padding: .75rem 2.25rem;
        @include smaller-than-medium {
          top: 17px;
          right: .5rem;
          padding: .5rem 1.5rem;
          &.fs-body-18 {
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>
